(function () {
  var menuDropdownLinks = document.querySelectorAll(".menu-dropdown-submenu-link");

  for (var i = 0; i < menuDropdownLinks.length; i++) {
    menuDropdownLinks[i].addEventListener('click', changeDisplayItems);
  }

  if (document.querySelectorAll(".mainpage").length > 0 && document.querySelector(".mainpage .site-body-menu")) {
    document.querySelector(".mainpage .site-body-menu").setAttribute("style", "height:" + (document.querySelector(".mainpage .menu-dropdown-expanded").offsetHeight + 24) + "px");
  }

  function changeDisplayItems(e) {
    var linkClassList = e.target.classList;

    if (linkClassList.contains("expanded-link")) {
      getMenuItems(document.querySelector(".menu-dropdown-expanded:not([data-root-menu])"));
    } else {
      getMenuItems(document.querySelector(".menu-dropdown-compact"));
    }
  }

  function getMenuItems(parent) {
    if (parent.querySelector('.menu-dropdown-submenu-link').classList.contains("icon-plus-before")) {
      changeClassList(parent.querySelectorAll(".menu-dropdown-item.ng-hide"), "ng-hide", "ng-show");
      changeClassList(parent.querySelectorAll(".menu-dropdown-submenu-link"), "icon-plus-before", "icon-minus-before");
    } else {
      changeClassList(parent.querySelectorAll(".menu-dropdown-item.ng-show"), "ng-show", "ng-hide");
      changeClassList(parent.querySelectorAll(".menu-dropdown-submenu-link"), "icon-minus-before", "icon-plus-before");
    }
  }

  function changeClassList(items, oldClass, newClass) {
    for (var i = 0; i < items.length; i++) {
      items[i].classList.add(newClass);
      items[i].classList.remove(oldClass);
    }
  }
})();