import '../scripts/extendScripts/menuDropdown/menuDropdown.js';
import appDependency from '../../../scripts/appDependency.js';
import subscribeModule from '../scripts/_partials/subscribe/subscribe.module.js';
appDependency.addItem(subscribeModule);
import '../fonts/fonts.scss';
import '../styles/common/buttons.scss';
import '../styles/common/custom-input.scss';
import '../styles/common/inputs.scss';
import '../styles/common/links.scss';
import '../styles/partials/footer-menu.scss';
import '../styles/partials/footer.scss';
import '../styles/partials/header.scss';
import '../styles/partials/menu-dropdown.scss';
import '../styles/partials/menu-general.scss';
import '../styles/partials/pagenumberer.scss';
import '../styles/partials/toolbar-top.scss';
import '../styles/partials/toolbar-bottom.scss';
import '../styles/partials/breads.scss';
import '../styles/partials/gallery.scss';
import '../styles/partials/product-categories.scss';
import '../styles/general.scss';
import '../styles/views/home.scss';
import '../styles/views/brands.scss';
import '../styles/views/news.scss';
import '../styles/views/product.scss';
import '../scripts/_common/carousel/carousel.module.js';
import '../scripts/_common/scrollToTop/scrollToTop.module.js';
import '../scripts/_partials/cart/cart.module.js';
import '../scripts/_partials/catalog-filter/catalogFilter.module.js';
import '../scripts/_partials/product-view/productView.module.js'; //import '../scripts/_partials/quickview/quickview.module.js';