import carouselModule from '../../../../../scripts/_common/carousel/carousel.module.js';
import ratingModule from '../../../../../scripts/_common/rating/rating.module.js';
import quickviewModule from '../../../../../scripts/_partials/quickview/quickview.module.js';
import colorsViewerModule from '../../../../../scripts/_partials/colors-viewer/colorsViewer.module.js';
import productsCarouselModule from '../../../../../scripts/_partials/products-carousel/productsCarousel.module.js';
import photoViewListModule from '../../../../../scripts/_partials/photo-view-list/photoViewList.module.js';
import './styles/product-view.scss';
import productViewService from '../../../../../scripts/_partials/product-view/services/productViewService.js';
import { productViewItemCustomDirective, productViewCarouselPhotosDirective, productViewChangeModeDirective, productViewModeDirective } from './directives/productViewDirectives.js';
import ProductViewItemCustomCtrl from './controllers/productViewItemController.js';
import ProductViewCarouselPhotosCtrl from '../../../../../scripts/_partials/product-view/controllers/productViewCarouselPhotosController.js';
import ProductViewChangeModeCtrl from '../../../../../scripts/_partials/product-view/controllers/productViewChangeModeController.js';
import ProductViewModeCtrl from '../../../../../scripts/_partials/product-view/controllers/productViewModeController.js';
const moduleName = 'productView';
angular.module(moduleName, [ratingModule, quickviewModule, colorsViewerModule, productsCarouselModule, photoViewListModule, carouselModule]).constant('viewPrefix', {
  desktop: '',
  mobile: 'mobile-',
  mobileModern: 'mobile-modern-'
}).constant('viewList', {
  desktop: ['tile', 'list', 'table'],
  mobile: ['tile', 'list', 'single'],
  mobileModern: ['tile', 'list', 'single']
}).service('productViewService', productViewService).controller('ProductViewItemCustomCtrl', ProductViewItemCustomCtrl).controller('ProductViewCarouselPhotosCtrl', ProductViewCarouselPhotosCtrl).controller('ProductViewChangeModeCtrl', ProductViewChangeModeCtrl).controller('ProductViewModeCtrl', ProductViewModeCtrl).directive('productViewItemCustom', productViewItemCustomDirective).directive('productViewCarouselPhotos', productViewCarouselPhotosDirective).directive('productViewChangeMode', productViewChangeModeDirective).directive('productViewMode', productViewModeDirective);
export default moduleName;